import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';

import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ROUTES } from './sidebar-items';
import { RouteInfo } from './sidebar.metadata';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, map, Observable, take } from 'rxjs';
import { Claims } from 'src/app/models/accounts/token';
import { ConfigService } from 'src/app/config/config.service';
import { ConfigurationService } from 'src/app/services/config/configuration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MenuItems } from 'src/app/models/configuration/configuration';
import { selectApplicationConfiguration } from 'src/app/store/selectors/app.selector';
import { Store } from '@ngrx/store';
import { LoadMenuData } from 'src/app/store/actions/app.action';



@Component({
  selector: 'bzg-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  public sidebarItems: Observable<RouteInfo[]>;
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  level4Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  showHomeColor:boolean=false;
  headerHeight = 60;
  menuList: MenuItems[] = [];
  routerObj: any = null;
  loggedIn: boolean = false;
  currentUser: Claims;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private router: Router,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private configService: ConfigurationService,
    private matSnackBar: MatSnackBar,
    private store: Store<any>,
  ) {
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const currenturl = event.url.split('?')[0];
        if(currenturl == "/"){
          this.renderer.addClass(document.body, 'body-homepage');
        }else{
          this.renderer.removeClass(document.body, 'body-homepage');
        }
        this.level1Menu = currenturl.split('/')[1];
        this.level2Menu = currenturl.split('/')[2];

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }

  async ngOnInit(): Promise<void> {

    this.store.dispatch(LoadMenuData());

    this.sidebarItems = this.store.select(selectApplicationConfiguration).pipe(
      map(config => config.menuList),
      filter(config => config != null),
    )

    //this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);

    this.initLeftSidebar();
    this.bodyTag = this.document.body;

    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
      )
      .subscribe((s) => {

        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
        if (this.loggedIn) {

          let claims = this.authService.instance.getActiveAccount().idTokenClaims;
          this.currentUser = JSON.parse(JSON.stringify(claims)) as Claims;
        }
      })

  }
  async getMenuList(data: MenuItems[]) {

    // var parentMenu:any[] = data.filter(s=>s.parentId ==0);
    // var childMenu:MenuItems[]=data.filter(s=>s.parentId>0);
    // this.menuList = parentMenu.map(function(x){ 
    //   var result=childMenu.filter(a1=> a1.parentId==x.menuId);
    //   console.log(result);
    //   if(result.length>0) {x.submenu = [...x.submenu,...result],x.class = "menu-toggle"}
    //   return x })
    //   this.sidebarItems = this.menuList;
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event: any) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }
  getitems(data: any) {
    const result: any = {};

    // Iterate over the input array
    for (const item of data) {
      // If the item doesn't have a parent, add it to the result object as a top-level key
      if (item.ParentId == 0) {
        result[item.menuId] = {
          menuId: item.menuId,
          parentId: item.parentId,
          title: item.title,
          displayOrder: item.displayOrder,
          path: item.path,
          menuFrom: item.menuFrom,
          pageId: item.pageId,
          status: item.status,
          moduleName: item.moduleName,
          icon: item.icon,
          class: item.class,
          groupTitle: item.groupTitle,
          submenu: {

          }
        };
      } else {
        // If the item has a parent, add it to the parent's children object
        result[item.ParentId].submenu[item.menuId] = {
          menuId: item.menuId,
          parentId: item.parentId,
          title: item.title,
          displayOrder: item.displayOrder,
          path: item.path,
          menuFrom: item.menuFrom,
          pageId: item.pageId,
          status: item.status,
          moduleName: item.moduleName,
          icon: item.icon,
          class: item.class,
          groupTitle: item.groupTitle,
          submenu: {}
        };
      }
    }

    // Return the resulting object
    return result;
    //console.log(result);
  }


  callLevel1Toggle(event: any, element: any) {

    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }
  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }
  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }
  callLevel4Toggle(event: any, element: any) {
    if (element === this.level4Menu) {
      this.level4Menu = '0';
    } else {
      this.level4Menu = element;
    }
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }

  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }

  checkStatuForResize(firstTime: any) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }
  mouseHover(e: Event) {
    const body = this.elementRef.nativeElement.closest('body');
    // console.log('mouseHover width', window.innerWidth);
    // console.log('mouseHover class list', body.classList);
    if (window.innerWidth < 1170) {
      if (body.classList.contains('submenu-closed')) {
        /*below 1170 screens intentially removed half side bar
       this.renderer.addClass(this.document.body, 'side-closed-hover');
       this.renderer.removeClass(this.document.body, 'submenu-closed');*/
      }
    }
  }
  mouseOut(e: Event) {
    const body = this.elementRef.nativeElement.closest('body');
    // console.log('mouseOut width', window.innerWidth);
    // console.log('mouseOut class list', body.classList);
    if (window.innerWidth < 1170) {
      if (body.classList.contains('side-closed-hover')) {
        /*below 1170 screens intentially removed half side bar
         this.renderer.removeClass(this.document.body, 'side-closed-hover');
         this.renderer.addClass(this.document.body, 'submenu-closed');*/
      }
    }

  }
  // convertToScript() {
  //   var cells = document.querySelectorAll(".sidebarListmainLi");
  //   console.log("ul => ", cells);
  //   for (var i = 0; i < cells.length; i++) {
  //     cells[i].addEventListener("mouseover", function (e) {
  //       let ul = e.target as HTMLUListElement;
  //       console.log("ul => ", ul);
  //       ul.getElementsByClassName('ml-menu')[0].setAttribute('style', 'color:red');
  //       //console.log(this.getElementsByClassName('ml-menu')[0], this.offsetTop)
  //     });
  //   }
  // }
  // ngAfterViewInit() {
  //   this.convertToScript();
  // }
}


